.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

input {
  background-color: black;
  color: white;
  font-size: 20px;
  font-size: max(20px, 0.5em);
  font-family: inherit;
  border: 2px solid lightgray;
  border-radius: 4px;
}

textarea {
  background-color: black;
  color: white;
  font-size: 20px;
  font-size: max(16px, 0.5em);
  font-family: inherit;
  border: 2px solid lightgray;
  border-radius: 4px;
}

select {
  background-color: black;
  color: white;
  font-size: 20px;
  font-size: max(16px, 0.5em);
  font-family: inherit;
  border: 2px solid lightgray;
  border-radius: 4px;
}

input[type=submit] {
  background-color: #FFDC00; /* For browsers that do not support gradients */
  background-image: linear-gradient(#FFDC00, #ccb100e1, #FFDC00);
  color: black;
  font-weight: bold;
  font-family: "Segoe UI", Helvetica, sans-serif;
  border: 2px solid #FFDC00;
}

input[type=submit]:disabled {
  background-color: lightgray;
  background-color: lightgray; /* For browsers that do not support gradients */
  background-image: linear-gradient(lightgray, rgb(136, 134, 134), lightgray);
  color: black;
  font-weight: bold;
  font-family: "Segoe UI", Helvetica, sans-serif;
  border: 2px solid lightgray;
}

.details {
  border: 2px solid #444444;
  padding: 5px;
  display: flex;
  font-size: 20px;
  justify-content: start;
  align-items: center;
  text-align: center;
}

.banner {
  border: 2px solid #FFDC00;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #FFDC00; /* For browsers that do not support gradients */
  background-image: linear-gradient(#FFDC00, #ccb100e1, #FFDC00);
}

.imageBtn {
  background-color: #ff9900; /* For browsers that do not support gradients */
  background-image: linear-gradient(#FFDC00, #ccb100e1, #FFDC00);
  font-weight: bold;
  color: black;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}